import SvgComponent from "./SvgComponent";

export const Header = ({ isEmbed }) => {
  const url = window.location.href.replace("?embed=true", "");
  if (isEmbed) {
    return (
      <div className="embeded-header">
        <img
        onClick={() => {
          window.open(url, "_blank");
        }}
        src="/fullscreen.png" alt="" />
      </div>
    );
  }

  return (
    <div className="header-img">
      <SvgComponent name={"ShowRoomLogo"} />
    </div>
  );
}
