import React, { useEffect, useState } from "react";
import ShareUrlPopup from "../components/ShareUrlPopup";
import { RWebShare } from "react-web-share";
import SvgComponent from "../components/SvgComponent";
import { Footer } from "../components/Footer";
import { Header } from "../components/Header";
import { useParams } from "react-router-dom";

const ItemDetail = ({ type }) => {
  const params = useParams();
  const { id } = params;
  const query = new URLSearchParams(window.location.search);
  const embed = query.get("embed") === "true";

  const [showShareUlrPopup, setShowShareUlrPopup] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // Load the script dynamically
    const script = document.createElement("script");
    if (script) {
      script.src =
        "https://cdn.scaleflex.it/plugins/js-cloudimage-360-view/latest/js-cloudimage-360-view.min.js";
      script.async = true;
      document.body.appendChild(script);
    }

    return () => {
      // Clean up by removing the script when component unmounts
      document.body.removeChild(script);
    };
  }, []);

  useEffect(() => {
    const removeAbbrElements = () => {
      const abbrElements = document.querySelector(
        ".cloudimage-360-icons-container"
      );
      // console.log(abbrElements);
      if (abbrElements) {
        abbrElements.remove();
      }
    };

    // Run the removal function when the component mounts and after DOM updates
    removeAbbrElements();

    // If the element appears later due to asynchronous loading, consider monitoring it
    const observer = new MutationObserver((mutationsList) => {
      for (let mutation of mutationsList) {
        if (mutation.type === "childList" && mutation.addedNodes.length > 0) {
          removeAbbrElements();
          break;
        }
      }
    });

    observer.observe(document.body, { subtree: true, childList: true });

    // Clean up the observer when the component unmounts
    return () => observer.disconnect();
  }, []);

  useEffect(() => {
    const checkElements = () => {
      const loaderElement = document.querySelector(".cloudimage-360-loader");
      const viewIconElement = document.querySelector(
        ".cloudimage-360-view-360-icon:not(#custom360)"
      );

      if (loaderElement && !viewIconElement) {
        setIsLoading(true);
      } else {
        setIsLoading(false);
      }
    };

    // Check initially on mount
    checkElements();

    // Set up a mutation observer to react to changes in the DOM
    const observer = new MutationObserver(() => {
      checkElements();
    });

    observer.observe(document.body, {
      subtree: true,
      childList: true,
      attributes: true,
    });

    // Clean up observer on component unmount
    return () => observer.disconnect();
  }, []);
  return (
    <>
      <div
        className="cloudimage-360"
        data-folder={`https://${process.env.REACT_APP_AWS_BUCKET_NAME}.s3.${process.env.REACT_APP_AWS_REGION}.amazonaws.com/images/${id}/`}
        data-filename-x={`{index}.${type}`}
        data-amount-x="36"
      >
        {isLoading && (
          <div className="cloudimage-360-view-360-icon" id="custom360">
            0%
          </div>
        )}
      </div>
      <Header isEmbed={embed} />

      <RWebShare
        data={{
          text: "XIX SHOWROOM 360",
          url: `${process.env.REACT_APP_URL}/${id}`,
          title: "XIX SHOWROOM 360",
        }}
        onClick={() => console.log("Success")}
      >
        <div className="share-image cursor-pointer">
          <SvgComponent name={"ShareIcon"} />
        </div>
      </RWebShare>
      <Footer onClickShare={setShowShareUlrPopup} isEmbed={embed} />
      <ShareUrlPopup
        image={`https://${process.env.REACT_APP_AWS_BUCKET_NAME}.s3.${process.env.REACT_APP_AWS_REGION}.amazonaws.com/images/${id}/1.${type}`}
        isOpenPop={showShareUlrPopup}
        setIsOpenPop={setShowShareUlrPopup}
        id={id}
      />
    </>
  );
};

export default ItemDetail;
