import SvgComponent from "./SvgComponent";

export const Footer = ({ onClickShare, isEmbed }) => {

  if (isEmbed) {
    return (
      <div className="footer-container embed">
        <a href="https://xix3d.com" rel="noreferrer" target="_blank">
          <img src="/PoweredByZenoBlack.png" alt="" />
        </a>
      </div>
    );
  }

  return (
    <div className="footer-container">
      <div className="footer-img">
        <SvgComponent name="ShowRoomLogo" />
      </div>
      <div className="footer-middle">
        <h1>RENDERED IN ZENO</h1>
      </div>

      <div
        onClick={() => onClickShare(true)}
        className="cursor-pointer footer-share"
      >
        <SvgComponent name={"ShareIcon"} />
      </div>
    </div>
  );
}
