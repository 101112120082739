import React from "react";

import ItemDetail from "./pages/ItemDetail";
import "./App.css";
import {
  Route,
  Routes,
} from "react-router-dom";

const App = () => {
  return (
    <>
      <Routes>
        <Route path="/:id" element={<ItemDetail type="webp" />} />
      </Routes>
    </>
  );
};

export default App;
