import "../styles/embed.css";
import { Code } from "./icons/code.svg";
import { useState } from "react";
import { CopyIcon } from "./icons/copy.svg";

export const EmbedModal = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [copySuccess, setCopySuccess] = useState('');
  
  const embedCode = `<iframe
    width="560"
    height="315"
    src="${window.location.href}?embed=true"
    title="360 view"
    frameborder="0"
    allow="accelerometer; clipboard-write; encrypted-media; gyroscope"
    allowfullscreen
  ></iframe>`;

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text).then(() => {
      setCopySuccess('Copied!');
      setTimeout(() => setCopySuccess(''), 2000);
    });
  };

  return (
    <>
      <div
        className="Demo__some-network embed cursor-pointer"
        onClick={() => setIsOpen(true)}
      >
        <Code />
      </div>
      {isOpen && (
        <div className="modal-overlay">
          <div className="modal-container">
            <div className="modal-header">
              <h2 className="modal-title">Embed this page</h2>
              <button className="close-button" onClick={() => setIsOpen(false)}>
                <span className="material-icons"> close </span>
              </button>
            </div>

            <div className="content-container">
              <p className="embed-description">
                Copy and paste this code into your website's HTML to embed this page:
              </p>
              <div className="code-container">
                <pre className="code-block">
                  <code>{embedCode}</code>
                </pre>
                <button
                  className="copy-button"
                  style={{
                    position: 'absolute',
                    top: '0.5rem',
                    right: '0.5rem'
                  }}
                  onClick={() => copyToClipboard(embedCode)}
                >
                  <CopyIcon size={16} />
                  {copySuccess || 'Copy'}
                </button>
              </div>
            </div>

            <div className="modal-footer">
              <button className="copy-button" onClick={() => setIsOpen(false)}>
                Close
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
